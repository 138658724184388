const inspectionRoute = [
  {
    component: () => import('@/views/clr/inspection/InspectionPage.vue'),
    name: 'clr.inspection',
    path: '/clr/inspection'
  }
];

const processCustomsPackageRoute = [
  {
    component: () => import('@/views/clr/processCustomsPackage/processCustomsPackagePage.vue'),
    name: 'clr.processCustomsPackage',
    path: '/clr/processCustomsPackage'
  },
  {
    name: 'clr.clearanceReturnPackageDelete.details',
    path: 'returnPackageDelete/details/:id',
    component: () =>
      import('@/views/clr/processCustomsPackage/components/clearanceReturnPackageDelete/details/returnParcelDetail.vue')
  }
];

const clearanceAdjustRoute = {
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'clr.amendment.awb',
  path: '/clr/clearance/adjust',
  meta: {
    clientAppRouteName: 'clr.amendment.awb',
    clientApp: 'client'
  }
};

const clearanceSubmitRoute = {
  name: 'clr.clearance.submit',
  path: '/clr/clearance/submit',
  component: () => import('@/views/clr/clearanceManualSubmit/clearanceManualSubmit.vue')
};

const iossCorrectRoute = {
  component: () => import('@/views/clr/iossCorrection/iossCorrectionPage.vue'),
  name: 'clr.ioss.correct',
  path: '/clr/ioss/correct'
};

const clearanceMonitorRoute = {
  component: () => import('@/views/clr/clearanceMonitor/clearanceMonitor.vue'),
  name: 'clr.clearanceMonitor',
  path: '/clr/clearance/monitor'
};

export default {
  name: 'clr',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    ...inspectionRoute,
    clearanceAdjustRoute,
    clearanceSubmitRoute,
    clearanceMonitorRoute,
    iossCorrectRoute,
    ...processCustomsPackageRoute
  ]
};
